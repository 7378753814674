import { Auth } from "aws-amplify";

export async function initiateLogin( customProvider, token, sessionType) {
   let customState = [token, sessionType].join("-");

   let options;
   if ( customProvider )
   {
      options = { customProvider: customProvider, customState: customState }
   }
   else
   {
      options = { customState: customState };
   }

   Auth.federatedSignIn( options )
      .catch( err => console.log(err) )
}