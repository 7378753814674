import './App.css';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { withAuthenticator, Button, Flex, View, Authenticator, Card } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { Fragment, useEffect, useRef, useState } from "react";
import logo from './TeleDaas_Colour.png';
import { initiateLogin } from "./Authentication";
import LaunchSimplicity from "./LaunchSimplicity";


Amplify.configure( {
   Auth: {
      region: process.env.REACT_APP_AWS_REGION,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_INTEGRATION_ID,
      mandatorySignIn: true,
      // Store the user details in the window storage, so they sign out when they close the window
      storage: window.sessionStorage,

      oauth: {
         domain: "auth.miradateledaas.com",
         scope: ['openid', 'aws.cognito.signin.user.admin'],
         redirectSignIn: "https://spike.dev-gateway.miradateledaas.com",
         redirectSignOut: "https://spike.dev-gateway.miradateledaas.com",
         responseType: 'code',
      }
   }
} );

export default function App()
{
   const [ searchParams ] = useSearchParams();
   const [ authState, setAuthState ] = useState( 'loading' );
   const [ user, setUser ] = useState( null );
   const [ token, setToken ] = useState( null );
   const [ sessionType, setSessionType ] = useState( null );
   const [ identityProvider, setIdentityProvider ] = useState( null )

   useEffect( () =>
   {
      setToken( searchParams.get( 'token' ) );
      setSessionType( searchParams.get( 'sessionType' ) );

      const unsubscribe = Hub.listen( 'auth', ( { payload: { event, data } }) =>
         {
            switch ( event )
            {
               case 'signIn':
                  setUser( data );
                  setAuthState( 'signedIn' )
                  break;

               case 'signOut':
                  setUser( null );
                  setAuthState( 'signedOut' )
                  break;

               case 'customOAuthState':
                  const [ stateToken, stateSessionType ] = data.split('-')
                  setToken( stateToken );
                  setSessionType( stateSessionType );
                  break;
            }
         }
      )

      Auth.currentAuthenticatedUser().then( user =>
         {
            setUser( user );
            setAuthState( 'signedIn' );
         }
      ).catch( e =>
      {
         setAuthState( 'signedOut' )
      } );

      return unsubscribe;
   }, [] );

   useEffect( () =>
   {
      if ( searchParams.get( 'token' ) === "1234" )
      {
         setIdentityProvider( "MiradaMedical" );
      }
      else if ( searchParams.get( 'token' ) === "5678" )
      {
         setIdentityProvider( "StubIdP" );
      }
      else
      {
         setIdentityProvider(null );
      }
   }, [ searchParams ] );

   return (
      <Fragment>
         <Flex id="content" direction="column" alignContent="center">
            { authState === 'signedIn' &&
              (
                 <LaunchSimplicity
                    user={ user }
                    token={ token }
                    sessionType={ sessionType }
                    signOut={ () => Auth.signOut() }
                 />
              )
            }
            { authState === 'signedOut' &&
              (
                 <div className="container-fluid">
                    <Button variant="dark" onClick={ () => initiateLogin( identityProvider, token, sessionType ) }>Sign In</Button>
                 </div>
              )
            }
         </Flex>
      </Fragment>
);
}