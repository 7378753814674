import axios from "axios";
import { Button, View } from "@aws-amplify/ui-react";
import logo from "./TeleDaas_Colour.png";
import { Fragment, useState } from "react";

export default function LaunchSimplicity( { user, token, sessionType, signOut } )
{
   const streamUrl = process.env.REACT_APP_STREAM_URL;
   const [ errorText, setErrorText ] = useState( "" );
   const [ loading, setLoading ] = useState( false );

   function getSimplicityLink()
   {
      // setLoading( true );
      if ( !token || !sessionType )
      {
         setErrorText("token and session type must be set" );
         return;
      }
      alert( "Calling " + streamUrl + '?' + 'token=' + token + '&sessionType=' + sessionType + " with authorisation " + user.getSignInUserSession().getIdToken().getJwtToken() )
      axios.get( streamUrl + '?' + 'token=' + token + '&sessionType=' + sessionType, {
         headers: { 'Authorization': `${ user.getSignInUserSession().getIdToken().getJwtToken() }` }
      } ).then( ( response ) =>
         {
            setLoading( false );
            if ( response.status === 200 && response.data )
            {
               console.log( response.data );
               window.location.assign( response.data );
            }
         }, ( error ) =>
         {
            setLoading( false );
            console.log( "Error retrieving the streaming link " + error );
            console.log( error.response.data );
            setErrorText( error.response.data );
         }
      ).catch( exception =>
      {
         setLoading( false );
         console.log( "Error retrieving the streaming link" );
         console.log( exception );
         setErrorText( "Unable to retrieve a streaming link, please try again" );
      } );
   }

   return (
      <Fragment>
         <View id="signOutButton">
            <Button onClick={ signOut }>Sign Out</Button>
         </View>
         <View width="100%" justifyContent="center">
            <img src={ logo } width="800" alt="Simplicit90Y, delivered by Mirada TeleDaaS" />
         </View>
         <View width="100%">
            <Button
               id="launchButton"
               variation="primary"
               size="large"
               isFullWidth={ true }
               loadingText="Loading"
               onClick={ getSimplicityLink }
               isLoading={ loading }
            >
               Launch Simplicit<sup>90</sup>Y
            </Button>
         </View>
         <View id="errorMessage" width="100%">
            { errorText }
         </View>
      </Fragment>
   )
}